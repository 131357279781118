<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="9" :md="9" :lg="9" class="m-b-5">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="3" :md="3" :lg="3" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
      </i-col>
      <i-col :xs="24" :sm="12" :md="12" :lg="12" class="m-b-5">
        <a
          style="float: right; line-height: 30px"
          @click="onShowCreateModal({id:0})"
          >+新增租赁空间</a
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>

    <Modal v-model="createSpaceModal" width="400">
      <p slot="header" class="text-center">{{formData.id?'编辑':'新增'}}租赁空间</p>
      <Form
        :model="formData"
        :rules="ruleValidate"
        ref="formData"
        label-position="top"
        autocomplete="on"
      >
        <Row :gutter="8">
          <i-col span="24">
            <FormItem label="编码" prop="code">
              <Input
                v-model.trim="formData.code"
                size="small"
                placeholder="请填写编码"
              />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="名称" prop="name">
              <Input
                v-model.trim="formData.name"
                size="small"
                placeholder="请填写名称"
              />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem
              label="类型"
              prop="resourceType"
            >
              <Select
                v-model.trim="formData.resourceType"
                size="small"
                filterable
                placeholder="请选择类型"
              >
                <Option
                  v-for="item in typeArray"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="备注" prop="remark">
              <Input
                v-model.trim="formData.remark"
                size="small"
                type="textarea"
                :rows="4"
                placeholder="填写备注"
              />
            </FormItem>
          </i-col>
        </Row>
      </Form>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="createSpaceModal = false"
          >取消</Button
        >
        <Button
          type="success"
          class="m-r-5"
          :loading="btnLoading"
          @click="onConfirm()"
          >确认</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import { getresourcetypelistbybigtype } from '@/api/product/asset'
import { spacePage, saveSpace, deleteSpace, updateSpace } from '@/api/product/leaseSpace'

export default {
  components: {
    // CreateInvestment
  },
  data () {
    return {
      rentTypeArray: [],
      statusArray: [],
      templateArray: [],

      orderSchdule: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: ''
      },
      tableData: [],
      columnManagedata: [
        {
          title: '编码',
          key: 'code'
        },
        {
          title: '名称',
          key: 'name'
        },
        { title: '类型', key: 'resourceTypeName' },
        {
          title: '备注',
          key: 'remark'
        },

        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$store.commit('set_space_pageType', 'detail')
                    this.$store.commit(
                      'set_space_spaceId',
                      params.row.id
                    )
                    // this.handleShowDetail(params.row.id)
                  }
                }
              },
              '详情'
            )
            const editButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.onShowCreateModal(params.row)
                  }
                }
              },
              '编辑'
            )
            const deleteButton = h(
              'a',
              {
                style: {
                  color: 'red',
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要删除【 ' + params.row.name + '】？</p>',
                      loading: true,
                      onOk: () => {
                        deleteSpace({
                          id: params.row.id
                        })
                          .then((res) => {
                            if (res && !res.errcode) {
                              this.$Notice.success({ desc: '操作成功' })
                              this.initPageData()
                            }
                            this.$Modal.remove()
                          })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              },
              '删除'
            )
            const returnBtns = []
            returnBtns.push(detailButton)
            returnBtns.push(editButton)
            returnBtns.push(deleteButton)
            return h('div', returnBtns)
          }
        }
      ],

      createSpaceModal: false,
      btnLoading: false,
      typeArray: [],
      formData: {
        id: 0,
        code: '',
        name: '',
        remark: '',
        resourceType: null
      },
      ruleValidate: {
        name: [
          { required: true, type: 'string', message: ' ', trigger: 'blur' }
        ],

        code: [
          { required: true, type: 'string', message: ' ', trigger: 'blur' }
        ],
        resourceType: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.initTypeArray()
    this.initPageData()
  },
  methods: {
    initPageData () {
      spacePage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    initTypeArray () {
      getresourcetypelistbybigtype({ resourceBigType: 3 }).then(res => {
        this.typeArray = res
      })
    },
    onShowCreateModal (params) {
      this.formData.id = params.id
      if (params.id) {
        this.formData.name = params.name
        this.formData.remark = params.remark
        this.formData.code = params.code
        this.formData.resourceType = params.resourceType
      } else {
        this.formData.name = ''
        this.formData.remark = ''
        this.formData.code = ''
        this.formData.resourceType = null
      }

      this.createSpaceModal = true
    },
    onConfirm () {
      const that = this
      that.$refs.formData.validate((valid) => {
        if (valid) {
          // that.$Modal.confirm({
          //   title: '操作提示',
          //   content: '确定要新建租赁空间？',
          //   onOk: () => {

          //   }
          // })
          that.btnLoading = true
          if (!this.formData.id) {
            saveSpace(that.formData)
              .then((res) => {
                if (res && parseInt(res)) {
                  that.$Notice.success({ desc: '操作成功' })
                  that.initPageData()
                  that.createSpaceModal = false
                }
              })
              .finally(() => {
                that.btnLoading = false
                that.createSpaceModal = false
              })
          } else {
            updateSpace(that.formData)
              .then((res) => {
                if (res && parseInt(res)) {
                  that.$Notice.success({ desc: '操作成功' })
                  that.initPageData()
                  that.createSpaceModal = false
                }
              })
              .finally(() => {
                that.btnLoading = false
                that.createSpaceModal = false
              })
          }
        }
      })
    }
  },
  watch: {
    beginUpdate () {
      this.initPageData()
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.rentalSpace.beginUpdate
    }
  }
}
</script>
